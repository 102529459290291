

import { defineComponent } from 'vue';
import store from "@/store";
import { Card, Table, DataList, Searchbar } from '@/ui/index';
import { SessionController, EmployeeController, PresenceController, TimeEntryController, WorkbreakController } from '@/controller/';
import { User, Company, Employee, Workbreak } from '@/model';
import { DateTimeUtils } from '@/utils';

export default defineComponent({
    name: "Dashboard", 
    components: { Card, Table, DataList, Searchbar },
    data(){
        return{
            store,
            sessionUser: {} as User,
            sessionCompany: {} as Company,
            sessionEmployee: {} as Employee,
            employees: [] as Array<any>,
            presences: [] as Array<any>,
            workbreaks: [] as Array<any>,
            searchInput: "",
            employeeTableData: {
                thFields: [
                    { title: this.$t("general.numberShort"), property: "number", type: "string", clickAction: "openDetail" },
                    { title: this.$t("employee.firstname"), property:"firstName", type: "string",  clickAction: "openDetail" }, 
                    { title: this.$t("employee.lastname"), property: "lastName", type: "string", clickAction: "openDetail" }, 
                    { title: this.$t("day.comeAt"), property: "presence.come", type: "string", convert: "convertTimeStampToTime" },
                    { title: this.$t("day.goAt"), property: "presence.go", type: "string", convert: "convertSecondsToTime" },
                    { title: this.$t("day.workbreaks"), property: "workbreakSum", type: "string", convert: "convertSecondsToTime" },
                    { title: this.$t("label.worktimesum"), property: "daySum", type: "string", convert: "convertSecondsToTime" }
                ],
                tbActions: [{ name: "openDetail", icon: store.getters.svgIcons.watch }]
            },
            gotEmployees: false,
            gotPresences: false,
            gotTimeEntries: false,
            gotWorkbreaks: false,
            activeDay: 0
        }
    },
    computed: {
        filteredEmployees() {
            let filtered = [] as Array<any>;
            if (this.searchInput) {
                this.employees.forEach((employee : any) => {
                    JSON.stringify(employee.firstName).toLowerCase().includes(this.searchInput.toLowerCase()) && filtered.push(employee);
                    !filtered.includes(employee) && JSON.stringify(employee.lastName).toLowerCase().includes(this.searchInput.toLowerCase()) && filtered.push(employee);
                })
            } else {
                filtered = this.employees;
            }
            console.log('filtered', filtered)
            return filtered;
        }        
    },
    created() {
        this.getSessionUser()
        this.getSessionCompany()
        this.getData()
        this.activeDay = DateTimeUtils.getDayNoonTimestamp(new Date())
    },
    methods:{
            //data
        getSessionUser() {
            this.sessionUser = SessionController.sessionUser
            this.sessionEmployee = SessionController.sessionEmployee
        },
        getSessionCompany() {
            this.sessionCompany = SessionController.sessionCompany;
        },
        getData() {
            this.getEmployees()
            this.getPresences()
            this.getWorkbreaks()
        },
        async getEmployees() {
            const res = await EmployeeController.fetchEmployees();
            if(!res.error){
                this.employees = res.items;
                this.gotEmployees = true
                this.checkDataLoaded()
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },
        async getPresences() {
            const day = DateTimeUtils.getMidnightToMidnight(new Date())
            const res = await PresenceController.getPresences(day.start, day.end, 0);
            if(!res.error){
                this.presences = res.items;
                this.gotPresences = true
                this.checkDataLoaded()
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }            
        },
        async getWorkbreaks() {
            const day = DateTimeUtils.getMidnightToMidnight(new Date())
            const res = await WorkbreakController.getWorkbreaks(day.start, day.end, 0);
            if(!res.error){
                this.workbreaks = res.items;
                this.gotWorkbreaks = true
                this.checkDataLoaded()
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }            
        },        
        async getTimeEntries(employee: Employee) {
            const timeentries = await TimeEntryController.fetchDayTimeEntries(employee.uid, this.activeDay)
            if (timeentries?.items) {
                employee.setTimeEntries(timeentries.items)
                employee.setRuntimeValues()
            }
        },

            //handlers
        handleTableActionClicked(action : string, item:any) {
            console.log("handleTable", action, item)
            switch(action) {
                case "openDetail":
                    this.$router.push('/administration/dashboard/employee/' + item.uid);
                    break
                case "confirmDelete":
                    //this.confirmDeleteEmployee(item);
                    break
                case "editEmployee":
                    //this.editEmployee(item.uid);
                    break
            }
        },      
        
            //function        
        checkDataLoaded() {
            //if (this.gotEmployees && this.gotPresences) {
            if (!this.gotTimeEntries && this.employees.length) {
                this.employees.forEach((e: Employee) => {
                    this.getTimeEntries(e)
                })
                this.gotTimeEntries = true;
            }
            console.log('checkDataLoaded', this.employees.length, this.presences.length, this.workbreaks.length)
            if (this.employees.length && this.presences.length) {
                this.presences.forEach(p => {
                    const employee = this.employees.find(e => e.uid == p.employee);
                    if (employee) employee.setPresence(p)
                })
            }
            if (this.employees.length && this.workbreaks.length) {
                this.workbreaks.forEach((wBreak: Workbreak) => {
                    console.log('workbreak', wBreak)
                    const employee = this.employees.find((e: Employee) => e.uid == wBreak.employee);
                    if (employee) employee.workbreaks.push(wBreak)
                })
                this.employees.forEach((employee: Employee) => employee.setRuntimeValues())
            } 
            //}
        }
    }
})
